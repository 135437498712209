import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{`Similar Portfolios`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-monthly/"
            }}>{`All-Stars Monthly`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/keller-lethargic-asset-allocation/"
            }}>{`Lethargic Asset Allocation`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/keller-classical-asset-allocation/"
            }}>{`Classical Asset Allocation`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: balanced growth`}</li>
        <li parentName="ul">{`Type: momentum strategy`}</li>
        <li parentName="ul">{`Invests in: ETFs tracking equities, fixed income, and hard assets`}</li>
        <li parentName="ul">{`Rebalancing schedule: monthly`}</li>
        <li parentName="ul">{`Taxation: 80% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $10,000`}</li>
      </ul>
      <p>{`Wouter J. Keller is a researcher at the VU University Amsterdam and the CEO of Flex Capital, an asset management firm based in the Netherlands. Together with Jan Willem Keuning, he published his `}<em parentName="p">{`Defensive Asset Allocation`}</em>{` (DAA) in a paper released in mid-2018.`}</p>
      <p>{`Keller's `}<em parentName="p">{`Defensive Asset Allocation`}</em>{` aims to provide a healthy, diversified asset allocation, with protection against sudden market downturns. To achieve this goal, the strategy combines relative momentum with a unique canary universe to determine the need for portfolio crash protection.`}</p>
      <p><em parentName="p">{`DAA`}</em>{` rebalances once per month, making the strategy low maintenance.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><a parentName="p" {...{
            "href": "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3212862"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "602px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "140.66666666666669%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAIAAADuuAg3AAAACXBIWXMAAA7DAAAOwwHHb6hkAAACdElEQVQ4y52U6ZKjIBSF+/1frysa0IioYVNDjJh2ScxUc0YTM1VdXfP9oC7g8W7AR9u24zgOv2NcmKap67qPr6+vx38xjuO3+H6/SymLohAeKSXG4wtFUQzD8Hg8Zs/j8RiG4aPv+2magiCQUpZlaRbqui7LUmttjKmqSms9juOr579ieD6fz9frtWka51zTNK3n4nHOtW3beKy1zrmN572HEIIRRhiGhJAgCPb7PaU0jmNK6X6/T9P0drt95wxx7EmShDGWJEkURVg5LBBCMCWEZFn29Hy73ZIkObyAaeSBw9WO4zhN03cxNqIoguf1R3C4blFK67re5MwYSz0wDocD4meMrXq28BTjkEzT1Pd913XDMMzzfP+RZ6sgHsfRGCOEwF/nF96mWNnkjFDzPEeoaZpGUYQsEDnnPEkSznmaptbajRj1pJQSQtbaUkpDD5ofLmitNyeMMcY5Z4xRSuHweDxaa+u6xlhVFUZrbd/3G8+Zh3uyBVwJ3JDX29K27fvxRMA4EmQhCAKEvS5+fn7GcbzxTAiJoigMwyAIqAf2brcLwxBHGlso3rNV8zwbY0pPVVUwtNZCCK01FrXW1tr1zXmK7/e7EGLNELYQoigKpRRspRQKobV+ilFtY4zWWkqplKrrWimlPZAppfABxq7rNjmjgagQMtx5sI5yrB8IITaesyzL8xyhHo/H3MMYW/vHOS+KAoZSap7nTcEQnta6qqrz+YyXyDnXdd11wTl3uVzec0ac6CoaTgjhnEspEdH6mBpjNtWe5/n1PHHOq6rquq5pmrIsT6dT0zSn08lai4ieD+DPj/56Af/lV+LXK/0m/gPaqw+m1/Qb1gAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Wouter J. Keller, Jan Willem Keuning, Breadth Momentum and the Canary Universe: Defensive Asset Allocation (DAA)",
                "title": "Wouter J. Keller, Jan Willem Keuning, Breadth Momentum and the Canary Universe: Defensive Asset Allocation (DAA)",
                "src": "/static/7b6393d28ef94f3409c4ee229c66bc84/32056/keller-defensive-asset-allocation.png",
                "srcSet": ["/static/7b6393d28ef94f3409c4ee229c66bc84/5a46d/keller-defensive-asset-allocation.png 300w", "/static/7b6393d28ef94f3409c4ee229c66bc84/0a47e/keller-defensive-asset-allocation.png 600w", "/static/7b6393d28ef94f3409c4ee229c66bc84/32056/keller-defensive-asset-allocation.png 602w"],
                "sizes": "(max-width: 602px) 100vw, 602px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span></a></p>
        <p><strong parentName="p">{`Source`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3212862"
            }}>{`Breadth Momentum and the Canary Universe`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://papers.ssrn.com/sol3/cf_dev/AbsByAuth.cfm?per_id=1935527"
            }}>{`Wouter J. Keller`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://indexswingtrader.blogspot.com/"
            }}>{`Jan Willem Keuning`}</a></li>
        </ul>
        <p><strong parentName="p">{`Learn More`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/fbertram/TuringTrader/blob/develop/BooksAndPubs/Keller_DAA.cs"
            }}>{`Source code`}</a></li>
        </ul>
      </Aside>
      <p>{`Keller’s `}<em parentName="p">{`Defensive Asset Allocation`}</em>{` exists in multiple flavors which differ in the composition of their universes. Our implementation of Keller’s strategy is true to the publication’s `}<em parentName="p">{`DAA-G12`}</em>{` variant. The strategy's operation can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`trade a universe of`}
          <ul parentName="li">
            <li parentName="ul">{`twelve risky assets covering U.S. and international equities, hard assets, long-term treasuries, corporate bonds, and high-yield bonds`}</li>
            <li parentName="ul">{`three cash assets including U.S. treasury bonds and investment-grade corporate bonds`}</li>
          </ul>
        </li>
        <li parentName="ul">{`determine cash fraction from number of assets with negative momentum within canary universe`}</li>
        <li parentName="ul">{`allocate capital to up to six risky assets, ranked by momentum and depending on cash fraction`}</li>
        <li parentName="ul">{`allocate remaining capital to the top-ranking cash asset`}</li>
      </ul>
      <p>{`For full detail and the rationale behind `}<em parentName="p">{`Defensive Asset Allocation`}</em>{` refer to Keller's detailed paper. Further, the C# source code for the strategy is included in the `}<em parentName="p">{`TuringTrader.org`}</em>{` open-source project repository.`}</p>
      <p>{`We like Keller's concept of controlling exposure to risky assets a lot, as it reflects the notion of risky assets often increasing their correlation in times of crisis. However, as the `}<em parentName="p">{`DAA-G12`}</em>{` variant’s canary universe contains only two assets, the strategy transitions into cash assets rather abruptly. Further, we are not fully convinced that the breadth of this narrow canary universe will always be a good indicator of market stress.`}</p>
      <h2>{`Diversification`}</h2>
      <p>{`Keller's method of distinguishing risky and cash assets, combined with the broad menu of asset classes ensures that the strategy is, in times of market stress, well diversified across asset classes. This is confirmed by the _Defensive Asset Allocation's low beta, as well as its meek characteristics in 2008, 2015, and 2020.`}</p>
      <p>{`However, it is worth pointing out that in seemingly calm and bullish periods, the strategy may have 100% exposure to a single asset class, even though across multiple markets. This leads to some tail risk, should markets shift faster than the strategy's canary universe.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`Overall the `}<em parentName="p">{`Defensive Asset Allocation`}</em>{` shows very docile behavior with steady returns over the last economic cycle: Not only has `}<em parentName="p">{`DAA`}</em>{` gracefully handled the 2008 recession, but it also excelled during the 2009 rebound.`}</p>
      <p>{`Compared to other momentum strategies, `}<em parentName="p">{`DAA`}</em>{`'s performance during the shifting markets of 2016 and 2018 trailed that of passive portfolios by a much lesser degree. `}<em parentName="p">{`DAA`}</em>{`'s method of selecting the cash asset with the highest momentum seems to help to reduce the drag on overall performance this asset class has on many other portfolios.`}</p>
      <p>{`The Monte-Carlo simulation shows that Keller’s strategy has clear value: Statistically, it successfully increases returns and reduces maximum drawdown. However, maximum drawdowns might be significantly higher than the backtest suggests, given that the backtest's drawdown ranks in a very low percentile.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p>{`Just like other strategies rotating assets, this strategy frequently causes taxable events. The chart above shows, that investors cannot expect `}<em parentName="p">{`Defensive Asset Allocation`}</em>{` to hold any asset long enough to qualify for long term treatment of capital gains. Consequently, `}<em parentName="p">{`DAA`}</em>{` works best in tax-deferred accounts.`}</p>
      <p>{`Keller’s strategy invests in up to 7 ETFs simultaneously. Therefore, it requires an account size of no less than $10,000 to function correctly.`}</p>
    </Review>
    <Notes mdxType="Notes">
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      